import React from "react";
// @ts-ignore
import { H4, UnorderedList, ListItem, Link, RelatedItems } from 'govuk-react';
// @ts-ignore
import { BLUE } from 'govuk-colours';

export type LinkSetType = {
  id: number;
  name: string;
  link: string;
  content: number;
};

export type RelatedContentType = {
  id: number;
  link_set: LinkSetType[];
  title: string;
};

export const RelatedContent = (content: any) => {
  const getLink = (link: any) => {
    if (link) {
      return (
        <ListItem key={link.name}>
          <Link className="govuk-body govuk-!-font-size-16" href={link.link}>{link.name}</Link>
        </ListItem>
      );
    }

    return null;
  };

  return (
    <RelatedItems style={{ borderTop: "2px solid" + BLUE }}>
      <H4 style={{ marginTop: "10px" }}>Related content</H4>
      <UnorderedList listStyleType="none">
        {content.content.map((link: any) => getLink(link))}
      </UnorderedList>
    </RelatedItems>
  )
};
