import { FunctionComponent } from "react"
// @ts-ignore
import { Footer } from "govuk-react-jsx"

const FooterLayout: FunctionComponent = () => {
  return (
    <Footer />
  )
}

export default FooterLayout