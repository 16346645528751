import { useState, useEffect } from "react"
import axios from "axios"
import Contact from "./index"
import { ContactResponseType } from "./types"

function ContactContainer() {
  const [loading, setLoading] = useState<boolean>(true)
  const [contactInfo, setContactInfo] = useState<ContactResponseType[]>()

  useEffect(() => {
    axios
      .get<ContactResponseType[]>(`${process.env.REACT_APP_API_LOCATION}/contact/`)
      .then((response) => {
        setContactInfo(response.data)
        setLoading(false)
      })
  }, [])

  return (
    <div>
      {!loading ? (
        <Contact contactType={contactInfo as ContactResponseType[]} />
      ) : (
        <p>Loading</p>
      )}
    </div>
  )
}

export default ContactContainer
