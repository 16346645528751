import { useEffect, useState } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { Main } from "govuk-react"
import axios from "axios"
import "./App.css"
import "./styles.scss"
import ContactContainer from "./pages/Contact/ContactContainer"
import ContactDetailContainer from "./pages/ContactDetail/ContactDetailContainer"
import FooterLayout from "./components/Layout/FooterLayout"
import HeaderContainer from "./components/Layout/HeaderContainer"
import HomepageContainer from "./pages/Home/HomepageContainer"

function App() {
  const [availableContactType, setAvailableContactType] = useState([])
  const [homepageInfo, setHomepageInfo] = useState("")
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_LOCATION}/contact/`)
      .then((x) => {
        if (x.data.length > 0) setAvailableContactType(x.data)
      })
      .catch(() => {})

    axios.get(`${process.env.REACT_APP_API_LOCATION}/home/`)
      .then((x) => {
        if (x.data.length > 0) setHomepageInfo(x.data[0])
      })
      .catch(() => {})
  }, [])

  return (
    <div>
      <Router>
        <HeaderContainer />
        <Main style={{ paddingTop: "0px" }}>
          <Switch>
            <Route path="/" exact component={HomepageContainer} />
            <Route path="/contact" exact component={ContactContainer} />
            <Route
              path="/contact/:type"
              exact
              component={ContactDetailContainer}
            />
          </Switch>
        </Main>
        <FooterLayout />
      </Router>
    </div>
  )
}

export default App
