import { FunctionComponent } from "react"
// @ts-ignore
import { Header } from "govuk-react-jsx"
// @ts-ignore
import { H1, Main } from "govuk-react"

type LayoutProps = {
  title: string
  link: string
}
const HeaderLayout: FunctionComponent<LayoutProps> = ({
  title,
  link,
  children,
}) => {
  return (
    <div>
      <Header serviceName={`${title}`} serviceUrlHref={`${link}`} />
      <Main>
        <H1>{title}</H1>
      </Main>
    </div>
  )
}

export default HeaderLayout
