import { useState } from "react"
import { useLocation, Redirect, useParams } from "react-router-dom"
import Contactsolution from "../../components/ContactSolution/ContactSolution"
import { ContactResponseType } from "../Contact/types"
import FormContainer from "../../components/Form/FormContainer"

interface ReasonStateLocation {
  reason: ContactResponseType
}

function ContactDetailContainer() {

  const [resolved, setResolved] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [showForm, setShowForm] = useState<boolean>(false)

  const location = useLocation<ReasonStateLocation>()
  const { type } = useParams<{type: string}>()
  const { reason } = location.state

  const { contactsolution_set, customform_set, label } = reason

  if (resolved && submitted) {
    return <Redirect to="/" />
  }

  return (
    <div>
      {contactsolution_set && !showForm && (
        <Contactsolution
          contactSolutions={contactsolution_set}
          label={label}
          resolved={resolved}
          setResolved={setResolved}
          setShowForm={setShowForm}
          setSubmitted={setSubmitted}
        />
      )}
      {customform_set && showForm && (
        <FormContainer
          formfield_set={customform_set[0].formfield_set}
          id={customform_set[0].id}
          type={type}
        />
      )}
    </div>
  )
}

export default ContactDetailContainer
