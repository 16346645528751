import { useState } from "react"
import { Link } from "react-router-dom"
import { ContactResponseType } from "./types"
// @ts-ignore
import { Radios, Button } from "govuk-react-jsx"

type ContactProps = {
  contactType: ContactResponseType[]
}

const Contact = ({ contactType }: ContactProps) => {
  const contactTypes = contactType?.map((contact) => {
    return { children: contact.reason, value: contact.reason }
  })

  const [selectedReason, setSelectedReason] = useState<ContactResponseType>()

  const handleOptionSelection = (reason: string) => {
    const contact = contactType.filter(
      (contact) => contact.reason === reason
    )[0]
    setSelectedReason(contact)
  }

  return (
    <div>
      <Radios
        className="govuk-radios"
        fieldset={{
          legend: {
            children: "I have a",
          },
        }}
        idPrefix="contactType"
        items={contactTypes}
        name="contactType"
        value={selectedReason?.reason}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleOptionSelection(e.target.value)
        }
      />
      <Link
        to={{
          pathname: `/contact/${selectedReason?.reason}` as string,
          state: {
            reason: selectedReason,
          },
        }}
      >
        <Button isStartButton>Next Page</Button>
      </Link>
    </div>
  )
}

export default Contact
