import axios from "axios"
import { useState, useEffect } from "react"
import HeaderLayout from "./HeaderLayout"

type HomeAPIResponse = {
  title: string
  text: string
  link: string
}
function HeaderContainer() {
  const [link, setLink] = useState<string>("")
  const [title, setTitle] = useState<string>("")

  useEffect(() => {
    axios
      .get<HomeAPIResponse[]>(`${process.env.REACT_APP_API_LOCATION}/home/`)
      .then((response) => {
        setLink(response.data[0].link)
        setTitle(response.data[0].title)
      })
      .catch(() => {})
  }, [])

  return link && title ? <HeaderLayout link={link} title={title} /> : null
}

export default HeaderContainer
