import React, { FunctionComponent, useState, useEffect } from "react"
import axios from "axios"
// @ts-ignore
import { Spinner } from "govuk-react"
import Homepage from "./index"
import { LinkSetType, RelatedContentType } from "./usefulLinks"

type HomePageResponse = {
  link: string
  text: string
  title: string
}

type LoadingProps = {
  error: string | undefined
}

const Loading: FunctionComponent<LoadingProps> = ({error}) => {
  if (error !== '' && error !== undefined) {
    return (
      <p className="govuk-error-message center-text">{error}</p>
    )
  }

  return (
    <div className="height-60">
        <Spinner />
        <p className="center-text space-left">Loading...</p>
    </div>
  )
}

function HomepageContainer() {
  const [loading, setLoading] = useState<boolean>(true)
  const [title, setTitle] = useState<string>("")
  const [link, setLink] = useState<string>("")
  const [homepageInfo, setHomepageInfo] = useState<string>("")
  const [relatedContent, setRelatedContent] = useState<LinkSetType[]>([])
  const [homepageErrorMessage, setHomepageErrorMessage] = useState<string | undefined>('')

  useEffect(() => {
    axios
      .get<HomePageResponse[]>(`${process.env.REACT_APP_API_LOCATION}/home/`)
      .then((response) => {
        const data = response.data[0]
        setLink(data.link)
        setHomepageInfo(data.text)
        setTitle(data.title)
        setLoading(false)
      })
      .catch((err) => setHomepageErrorMessage(err.message))

    axios
      .get<RelatedContentType[]>(`${process.env.REACT_APP_API_LOCATION}/related-content/`)
      .then((response) => {
        const data = response.data[0].link_set
        setRelatedContent(data)
      })
      .catch(() => {})
  }, [])

  return (
    <div className="govuk-width-container">
      <main className="govuk-main-wrapper"  id="main-content" role="main">
      {!loading ? (
        <Homepage
          link={link}
          title={title}
          homepageInfo={homepageInfo}
          relatedContent={relatedContent}
        />
      ) : (
        <Loading error={homepageErrorMessage} />
      )}
      </main>
    </div>
  )
}

export default HomepageContainer
