// @ts-ignore
import { Button } from "govuk-react-jsx"
import { Link } from "react-router-dom"
import { LinkSetType, RelatedContent } from "./usefulLinks"
import "./HomepageContainer.css"

type HomepageProps = {
  title: string;
  homepageInfo: string;
  link: string;
  relatedContent: LinkSetType[];
};

const Homepage = ({ homepageInfo, relatedContent }: HomepageProps) => {
  return (
    <div className="govuk-grid-row">
      <div className="govuk-grid-column-two-thirds">
        {homepageInfo && (
          <div
            className="govuk-body"
            dangerouslySetInnerHTML={{
              __html: homepageInfo,
            }}
          ></div>
        )}
        <Link to="/contact">
          <Button isStartButton>Start now</Button>
        </Link>
      </div>
      <div className="govuk-grid-column-one-third">
        <RelatedContent content={relatedContent} />
      </div>
    </div>
  );
};

export default Homepage;
