import { useState, FunctionComponent } from "react"
import axios from "axios"
import { CustomFormType, FormFieldType } from "../../pages/Contact/types"
import { Formik, Form } from "formik"
// @ts-ignore
import {Button, DateInput, FileUpload, Input, Radios, Select, Textarea} from "govuk-react-jsx"
// @ts-ignore
import { Spinner } from "govuk-react"
import * as yup from "yup"

type DisplayFormInputProps = {
  values: any
  field: FormFieldType
  handleChange: any
  touched: any
  errors: any
}

const InProgress: FunctionComponent = () => {
  return (
    <div style={{width: "60px", height: "20px", justifyContent: "center"}}>
      <Spinner />
    </div>
  )
}

const DisplayFormInput: FunctionComponent<DisplayFormInputProps> = ({
  values,
  field,
  handleChange,
  touched,
  errors,
}) => {
  const value = values[field.name]
  if (field.type === "text" || field.type === "email")
    return (
      <Input
        id={`id-${field.name}`}
        key={`${field.type}-${field.name}`}
        label={{
          children: `${field.label}`,
        }}
        name={field.name}
        placeholder={field.help_text}
        type={field.type}
        onChange={handleChange}
        value={value}
        {...(errors[field.name] && {
          errorMessage: {
            children: errors[field.name],
          },
        })}
        {...(field.required && { required: "" })}
      />
    )

  if (field.type === "file_upload")
    return (
      <FileUpload
        id={`file-upload-${field.name}-id`}
        key={`${field.type}-${field.name}`}
        label={{
          children: field.label,
        }}
        name={field.name}
        placeholder={field.help_text}
        onChange={handleChange}
        value={value}
        {...(field.required && { required: "" })}
        {...(errors[field.name] && {
          errorMessage: {
            children: errors[field.name],
          },
        })}
      />
    )

  if (field.type === "textarea") {
    const showCounter = Number(field?.max_length) > 0
    const remainingCharactersNumber = Number(field.max_length) - value.length

    return (
      <div className="govuk-character-count" data-module="govuk-character-count" data-maxlength="200">
        <div className="govuk-form-group">

            <Textarea
              id={`${field.name}-id`}
              key={`${field.type}-${field.name}`}
              label={{
                children: field.label,
              }}
              name={field.name}
              placeholder={field.help_text}
              onChange={handleChange}
              value={value}
              {...(field.required && { required: "" })}
              {...(errors[field.name] && {
                errorMessage: {
                  children: errors[field.name],
                },
              })}
            />
        </div>

        {(showCounter && remainingCharactersNumber >= 0) && (<div id="with-hint-info" className="govuk-hint govuk-character-count__message" aria-live="polite">
          You can enter up to {remainingCharactersNumber} characters
        </div>)}
        {(showCounter && remainingCharactersNumber < 0) && (<div id="with-hint-info" className="govuk-character-count__message govuk-error-message" aria-live="polite">
          You have {remainingCharactersNumber * -1} characters to many
        </div>)}
      </div>
    )
  }

  if (field.type === "date")
    return (
      <DateInput
        id={`${field.name}-id`}
        key={`${field.type}-${field.name}`}
        label={{
          children: field.label,
        }}
        name={field.name}
        placeholder={field.help_text}
        onChange={handleChange}
        value={value}
        {...(field.required && { required: "" })}
        {...(errors[field.name] && {
          errorMessage: {
            children: errors[field.name],
          },
        })}
      />
    )

  if (field.type === "radio") {
    const formattedValues = field.formfieldoptions_set?.map((option) => {
      return { children: option.value, value: option.value }
    })
    return (
      <Radios
        fieldset={{
          legend: {
            children: field.label,
          },
        }}
        items={formattedValues}
        name={field.name}
        placeholder={field.help_text}
        value={value}
        onChange={handleChange}
        {...(field.required && { required: "" })}
        {...(errors[field.name] && {
          errorMessage: {
            children: errors[field.name],
          },
        })}
      />
    )
  }

  if (field.type === "drop_down") {
    const formattedValues = field.formfieldoptions_set?.map((option) => {
      return { children: option.value, value: option.value }
    })

    return (
      <Select
        id={`${field.name}-id`}
        key={`${field.type}-${field.name}`}
        label={{
          children: field.label,
        }}
        name={field.name}
        items={formattedValues}
        placeholder={field.help_text}
        onChange={handleChange}
        value={value}
        {...(field.required && { required: "" })}
        {...(errors[field.name] && {
          errorMessage: {
            children: errors[field.name],
          },
        })}
      />
    )
  }
  // The support for different types of field has to be extended
  return null
}

const FormContainer: FunctionComponent<CustomFormType> = ({
  formfield_set,
  type,
  validation,
  children,
}) => {
  const [status, setStatus] = useState<number>()
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [formMessage, setFormMessage] = useState<string>(
    'Thank you for your enquiry. We will get back to you as soon as possible.'
  )
  const [submitting, setSubmitting] = useState<boolean>(false)

  const orderedFields = formfield_set.sort((a, b) => a.order - b.order)

  const emailFieldList = orderedFields.filter(field => field.type === "email")

  const getInitialValues = () => {
    const initialValues: {[key:string]: any} = {}

    formfield_set.forEach(field => {
      initialValues[field.name] = ""
      }
    )

    return initialValues
  }

  const getValidationSchema = () => {
    const validationObect: {[key: string]: any} = {}
    orderedFields.forEach((field: FormFieldType) => {
      switch (field.type) {
        case 'text':
        case 'textarea':
          let textValidationObject = yup.string()
          textValidationObject = field.min_length ?  textValidationObject.min(Number(field.min_length)) : textValidationObject
          textValidationObject = field.max_length ?  textValidationObject.max(Number(field.max_length)) : textValidationObject
          textValidationObject = field.required ?  textValidationObject.required() : textValidationObject
          validationObect[field.name] = textValidationObject
          break
        case 'number':
          validationObect[field.name] = field.required ? yup.number().required() : yup.number()
          break
        case 'email':
          let emailValidationObject = yup.string()
          emailValidationObject = (emailFieldList.length > 1 && emailFieldList[0].name !== field.name)
            ? emailValidationObject.email().oneOf([yup.ref(emailFieldList[0].name), null], "Provide your email in the other field as well").when(
              // @ts-ignore
              emailFieldList[0].name, (val) => {
                if (val) {
                  return yup.string().email().oneOf([yup.ref(emailFieldList[0].name), null], "The email does not match").required("Confirm the email")
                }
              })
            : emailValidationObject.email()
          emailValidationObject = field.required ? emailValidationObject.required() : emailValidationObject
          validationObect[field.name] = emailValidationObject
          break
          case 'drop-down':
            validationObect[field.name] = field.required ? yup.string().ensure().required() : yup.string().ensure()
            break
        default:
          break
      }
    })

    return yup.object().shape(validationObect)
  }

  if (status === 200 && submitted) {
    return (
      <div className="govuk-body"
        dangerouslySetInnerHTML={{
          __html: formMessage,
        }}>
      </div>
    )
  }

  if (status !== 200 && submitted) {
    return (
      <div className="govuk-body">
        Sorry, we're facing some technical difficulties right now. Please try again later.
      </div>
    )
  }

  return (
    <Formik
      initialValues={{...getInitialValues()}}
      validationSchema={getValidationSchema()}
      onSubmit={(values: {[key: string]: any}) => {
        let data = new FormData();
        setSubmitting(true)

        for (const [key, value] of Object.entries(values)){
          data.append(key, value)
        }
        data.append("type", type as string)
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/contact-phe/`, data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            setSubmitted(true)
            setStatus(response.status)
            setFormMessage(response.data)
          })
          .catch(err => {
            setSubmitted(true)
            setStatus(500)
          })
        }
      }
      >
        {(formik) => {
          const {handleChange, values, errors, touched, isValid, dirty} = formik

          return (
            <Form>
              {orderedFields.map((field) =>
                DisplayFormInput({values, field, handleChange, errors, touched})
              )}
              <Button type="submit">
                {submitting ? <InProgress /> : "Submit"}
              </Button>
            </Form>
          )
        }}
    </Formik>
  )
}

export default FormContainer
